.h5_detail_page {
  display: flex;
  padding-top: 20px;
  width: 100vw;
  flex-direction: column;
  box-sizing: border-box;
  height: 100vh;
  min-height: 740px;
  overflow: hidden;
}
.h5_detail_page .session_region {
  flex: 1;
  overflow-y: scroll;
}
