.product_region {
  box-sizing: border-box;
  padding: 12px 20px;
  overflow: hidden;
}
.product_region .top {
  margin-top: 16px;
}
.product_region .section_title {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #999;
  margin: 0 0 20px;
}
.product_region .product_box {
  display: flex;
  position: relative;
  left: 0;
  transition: all 1s;
}
.product_region .product_item {
  display: flex;
  width: 350px;
  padding: 12px;
  flex-direction: row;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin-right: 30px;
}
.product_region .product_item .product_img {
  align-self: center;
  display: block;
  width: 100px;
  height: 100px;
  margin-right: 12px;
}
.product_region .product_item .right_box {
  flex: 1;
}
.product_region .product_item .top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
}
.product_region .product_item .product_title {
  display: block;
  width: 150px;
  font-size: 16px;
  font-weight: bold;
  color: #141416;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  word-break: break-all;
  display: -webkit-box;
  white-space: pre-wrap;
  -webkit-box-orient: vertical;
}
.product_region .product_item .product_price {
  color: #3369ff;
  font-size: 24px;
  font-weight: bold;
}
.product_region .product_item .bottom {
  display: flex;
  flex-direction: row;
}
.product_region .product_item .view_btn {
  margin-right: 10px;
}
.product_region .product_item .oper_btn {
  text-align: center;
  padding: 8px 30px;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: #333;
  color: #fff;
  cursor: pointer;
}
