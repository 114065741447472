.session_item_region {
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-bottom: 20px;
  padding-right: 100px;
}
.session_item_region .avatar_box {
  margin-right: 20px;
  text-align: center;
  color: #333;
}
.session_item_region .avatar_box .avatar_img {
  width: 48px;
  height: 48px;
  border-radius: 5px;
}
.session_item_region .user_avatar {
  margin: 0 0 0 20px;
}
.session_item_region .list_item {
  padding: 0;
  justify-content: flex-start;
  align-items: center;
}
.session_item_region .list_item .left {
  width: 200px;
  border-right: 1px solid #ccc;
}
.session_item_region .list_item .right {
  padding-left: 20px;
  flex: 1;
  text-align: left;
}
.session_item_region .tag_item,
.session_item_region .list_tag_item {
  margin: 0 20px 10px 0;
  display: inline-block;
  padding: 8px 32px;
  box-sizing: border-box;
  min-width: 80px;
  text-align: center;
  border-radius: 10px;
  border: 1px solid #d9d9d9;
  color: #767676;
  cursor: pointer;
  font-size: 14px;
}
.session_item_region .list_tag_item {
  display: flex;
  padding: 5px 10px;
  margin: 10px;
  justify-content: center;
}
.session_item_region .select_tag {
  background-color: #333;
  color: #fff;
}
.session_item_region .text_box {
  flex: none;
  min-width: 150px;
  text-align: center;
  max-width: 90%;
  background-color: #edf0f5;
  border-radius: 0 20px 20px 20px;
  padding: 10px;
  color: #000;
}
.session_item_region .loading_box {
  height: 100px !important;
  position: relative !important;
}
.session_item_region .name {
  font-size: 14px;
  font-weight: bold;
  color: #23262f;
  margin-bottom: 5px;
}
.session_item_region .session_time {
  font-size: 14px;
  color: #333;
}
.session_item_region .session_desc {
  font-size: 16px;
  color: #000;
  margin-bottom: 16px;
  text-align: left;
}
.session_item_region .tag_box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.session_item_region .ant-table-wrapper .ant-table {
  background-color: transparent;
  border: 1px solid #d9d9d9;
}
.session_item_region .ant-table-wrapper .ant-table-tbody > tr > td {
  border-bottom: 1px solid #d9d9d9;
}
.session_item_region .ant-table-content .ant-table-cell {
  border-inline-end: 1px solid #d9d9d9 !important;
}
.user_session_item {
  justify-content: flex-end;
  padding: 0 0 0 100px;
}
.user_session_item .text_box {
  flex: none;
  min-width: 150px;
  background-color: #5f85ee;
  border-radius: 20px 0 20px 20px;
  padding: 10px;
  color: #fff;
  box-sizing: border-box;
  height: max-content;
}
.user_session_item .session_desc {
  color: #fff;
  margin: 0;
}
