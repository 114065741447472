.h5_desc_section {
  box-sizing: border-box;
  border-bottom: 1px solid #ddd;
  flex-shrink: 0;
  padding: 0 20px 12px;
}
.h5_desc_section .top_region {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.h5_desc_section .left_box {
  display: flex;
  align-items: center;
}
.h5_desc_section .go_back_page_btn {
  font-size: 20px;
  margin-right: 10px;
}
.h5_desc_section .session_icon {
  display: inline-block;
  width: 32px;
  height: 32px;
  margin-right: 10px;
}
.h5_desc_section .session_name {
  font-size: 20px;
  font-weight: bold;
  width: 100px;
  margin: 0;
  line-height: 40px;
}
.h5_desc_section .session_name_input {
  max-width: 300px;
}
.h5_desc_section .icon_box {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-left: 40px;
  align-items: center;
  cursor: pointer;
}
.h5_desc_section .edit_text {
  font-size: 16px;
  margin-right: 10px;
  color: #333;
}
.h5_desc_section .oper_icon {
  margin-right: 20px;
  font-size: 20px;
}
.h5_desc_section .desc_box {
  font-size: 12px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.h5_desc_section .desc_item {
  margin-right: 10px;
  width: max-content;
  border-radius: 8px;
  position: relative;
  border: 1px solid #ddd;
  padding: 10px;
  color: #333;
  margin-bottom: 8px;
}
.h5_desc_section .del_btn {
  position: absolute;
  color: #ccc;
  top: -5px;
  right: -5px;
  font-size: 16px;
}
