.warp {
  display: flex;
  flex-direction: column;
}
.session_container {
  margin: 0 auto;
  min-width: 1500px;
  width: 100%;
  min-height: 800px;
  overflow: hidden;
  height: 97vh;
  display: flex;
  flex-direction: row;
}
.session_container .left_region {
  display: flex;
  flex-direction: column;
  border-right: 1px solid #ccc;
  background-color: #f7f7f7;
  width: 350px;
  height: 100%;
  flex-shrink: 0;
}
.session_container .left_region .avatar {
  padding: 0 20px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.session_container .left_region .avatar .avatar_img {
  width: 120px;
  height: 120px;
  border-radius: 120px;
  background-color: antiquewhite;
  margin: 15px auto;
}
.session_container .left_region .robot_name {
  width: 85%;
  margin: 0 auto;
  font-size: 26px;
  color: #000;
  font-weight: bold;
  position: relative;
  text-align: center;
}
.session_container .left_region .edit_robot_name_input {
  width: 60%;
  text-align: left;
  font-size: 20px;
}
.session_container .left_region .edit_seller_name_icon {
  color: #4971f6;
  font-size: 28px;
  position: absolute;
  right: 0;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  padding: 5px;
  border-radius: 10px;
}
.session_container .left_region .add_btn {
  margin: 20px 20px 30px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  font-size: 18px;
  color: #4971f6;
  cursor: pointer;
  font-weight: bold;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}
.session_container .left_region .add_btn_icon {
  display: inline-block;
  margin-right: 10px;
  font-weight: bold;
}
.session_container .left_region .session_list_container {
  padding-bottom: 40px;
  flex: 1;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.session_container .right_region {
  flex: 1;
  max-height: 100%;
  display: flex;
  flex-direction: column;
}
.session_container .right_region .session_region {
  flex: 1;
  overflow-y: scroll;
}
.session_container .right_region .product {
  flex-shrink: 0;
}
.session_container .right_region .desc,
.session_container .right_region .session_region {
  border-bottom: 1px solid #ccc;
}
.warp .fillings {
  font-size: 12px;
  background-color: #acaaaa;
  color: #f7f7f7;
  width: 100%;
  height: 3vh;
  display: flex; 
  justify-content: center; 
  align-items: center;
}
