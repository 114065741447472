.session_list {
  width: 100%;
  padding: 15px 20px;
  box-sizing: border-box;
  overflow-y: scroll;
}
.session_list .item_container {
  padding: 0;
  position: relative;
  margin-bottom: 16px;
}
.session_list .list_item {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 40px;
  padding: 10px 20px;
  cursor: pointer;
  position: relative;
  background: #ececec;
  border-radius: 10px;
}
.session_list .active_item {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  position: relative;
  border: none;
  background-color: #fff;
}
.session_list .delete_session_btn {
  position: absolute;
  top: -5px;
  right: -5px;
  color: #999;
  z-index: 1;
}
.session_list .case_name {
  font-size: 18px;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.session_list .case_created_at {
  font-size: 14px;
  color: #333;
}
