.h5_product_region {
  height: auto;
  box-sizing: border-box;
  padding: 12px 20px;
  box-shadow: -4px 0px 25px 0px rgba(0, 0, 0, 0.15);
  transition: all 2s;
}
.h5_product_region .top {
  margin-top: 16px;
}
.h5_product_region .toggle_btn {
  margin-right: 4px;
  font-size: 10px;
}
.h5_product_region .section_title {
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  color: #777e90;
  margin: 0 0 20px;
}
.h5_product_region .scroll_box {
  overflow: hidden;
}
.h5_product_region .product_box {
  display: flex;
  position: relative;
  left: 0;
  transition: all 1s;
  padding-bottom: 10px;
}
.h5_product_region .product_item {
  padding: 6px 12px;
  flex-direction: row;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin-right: 16px;
  position: relative;
}
.h5_product_region .product_item .product_img {
  align-self: center;
  display: block;
  width: 50px;
  height: 50px;
  margin: 0 auto;
}
.h5_product_region .product_item .product_title {
  display: block;
  font-size: 12px;
  font-weight: bold;
  color: #141416;
  margin: 5px auto 10px;
  text-align: center;
  display: -webkit-box;
  /* 必须设置此项才能生效 */
  -webkit-line-clamp: 2;
  /* 限制最大行数 */
  -webkit-box-orient: vertical;
  /* 垂直布局 */
  overflow: hidden;
  /* 超出部分隐藏 */
  text-overflow: ellipsis;
  /* 显示省略号 */
}
.h5_product_region .product_item .product_price {
  color: #fff;
  font-size: 12px;
  border-radius: 10px 0 10px 0;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #3369ff;
  padding: 2px 10px;
}
.h5_product_region .product_item .oper_btn {
  width: 85px;
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  text-align: center;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: #333;
  color: #fff;
  cursor: pointer;
}
.h5_product_region .product_item .view_btn {
  margin-bottom: 4px;
}
.h5_product_hide {
  height: 40px !important;
}
