.h5_index_page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 24px;
  box-sizing: border-box;
}
.h5_index_page .avatar_img {
  display: block;
  width: 100px;
  height: 100px;
  margin: 36px auto 0;
  background-color: antiquewhite;
  border-radius: 50px;
}
.h5_index_page .robot_name {
  width: 85%;
  margin: 20px auto 0;
  font-size: 26px;
  color: #000;
  font-weight: bold;
  position: relative;
  text-align: center;
}
.h5_index_page .edit_robot_name_input {
  width: 60%;
  text-align: left;
  font-size: 20px;
}
.h5_index_page .edit_seller_name_icon {
  color: #4971f6;
  font-size: 28px;
  position: absolute;
  right: 0;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  padding: 5px;
  border-radius: 10px;
}
.h5_index_page .add_btn {
  margin: 24px 0 36px;
  color: #fff;
  background-color: #5d52ee;
  height: 52px;
  line-height: 52px;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
  font-weight: bold;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
}
.h5_index_page .add_btn_icon {
  display: inline-block;
  margin-right: 10px;
  font-weight: bold;
}
.h5_index_page .item_container {
  padding: 0;
  position: relative;
}
.h5_index_page .list_item {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 52px;
  box-sizing: border-box;
  padding: 0 16px;
  cursor: pointer;
  position: relative;
  background: #ececec;
  border-radius: 16px;
  margin-bottom: 16px;
}
.h5_index_page .active_item {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  background: #fff;
  position: relative;
  border: none;
}
.h5_index_page .delete_session_btn {
  position: absolute;
  top: -5px;
  right: -5px;
  color: #999;
  z-index: 1;
  font-size: 20px;
}
.h5_index_page .case_name {
  font-size: 18px;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.h5_index_page .case_created_at {
  font-size: 14px;
  color: #333;
}
